import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { SistemaService } from '../../../../services/sistema/sistema.service';
import { QuitSpacesService } from '../../../../services/quit-spaces.service';
import { Search } from '../../../../models/search.model';
import { Router } from '@angular/router';
import { HomeService } from '../../../../services/home.service';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

declare var $: any;


@Component({
  selector: 'mat-search-bar',
  templateUrl: './mat-search-bar.component.html',
  styleUrls: ['./mat-search-bar.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('true', style({ width: '*' })),
      state('false', style({ width: '0' })),
      transition('true => false', animate('300ms ease-in-out')),
      transition('false => true', animate('300ms ease-in-out'))
    ])
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MatSearchBarComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class MatSearchBarComponent implements OnInit, OnDestroy {
  @ViewChild('input', { static: false }) inputElement: ElementRef;

  @Output() onBlur = new EventEmitter<string>();
  @Output() onClose = new EventEmitter<void>();
  @Output() onEnter = new EventEmitter<string>();
  @Output() onFocus = new EventEmitter<string>();
  @Output() onOpen = new EventEmitter<void>();

  @Output() public closeActive = new EventEmitter();

  searchVisible = false;
  url = '';
  // browserForm:FormGroup;
  browseText = '';
  letterSelected = ''; // para saber de que tipo esta seleccionando el browserText
  search: Search;
  resultSearch: ResultSearch[] = [];
  logger = false;

  espejoText = '';

  searchControl = new FormControl(null, Validators.minLength(3));
  unsubscribe = new Subject();

  constructor(private http: HttpClient,
    private _sistemaService: SistemaService,
    private router: Router,
    private _quitSpacesService: QuitSpacesService,
    private homeService: HomeService) {
    this.url = _sistemaService.url;
    this.logger = _sistemaService.logger;
  }

  ngOnInit() {
    this.searchControl.valueChanges
      .pipe(takeUntil(this.unsubscribe), debounceTime(250))
      .subscribe(value => {
        this.setTextBrowse(value);
        if (this.searchControl.valid) {
          this.searchText(value);
        } else {
          this.resultSearch = [];
          this.search = null;
        }
      });
  }

  @HostListener('document:keydown.enter', ['$event'])
  enter(): void {
    this.browse();
  }

  async searchQuery(query: string): Promise<any> {
    return this.homeService.searchAutocomplete(query).toPromise();
  }

  public close(): void {
    this.searchVisible = false;
    $(".mat-search_field .mat-form-field-infix").css("min-width", "auto");
    $(".logoDarwin").css("display", "block");
    $("#logoNoLogin").css("display", "block");

    $(".logoDarwin2").removeClass("d-none");
    $(".logoDarwin2").addClass("d-block");

    $(".iconoBambaNoLogin").removeClass("d-none");
    $(".iconoBambaNoLogin").addClass("d-block");

    $(".searchFloat").css("position", "relative");
    $(".searchFloat").css("left", "0");
    $(".searchFloat").css("z-index", "0");

    $(".tapaIMG").css("display", "none")
    $(".tapaIMG2").css("display", "none")
    $(".iconMenu").css("display", "block")
    //   this.onClose.emit();
  }

  public open(): void {
    let anchoPantalla = $(window).width();
    this.searchVisible = true;
    this.inputElement.nativeElement.focus();

    if (anchoPantalla >= 1680) {
      this.searchNormal(anchoPantalla, 90);

    } else if (anchoPantalla >= 1400) {
      this.searchNormal(anchoPantalla, 80);

    } else if (anchoPantalla >= 1250) {
      this.searchNormal(anchoPantalla, 85);


    } else if (anchoPantalla >= 1070) {
      this.searchNoFloat(anchoPantalla, 75);

    } else if (anchoPantalla >= 992) {
      this.searchNoFloat(anchoPantalla, 85);

    } else if (anchoPantalla >= 767) {
      this.searchNoFloat(anchoPantalla, 55);

    } else if (anchoPantalla >= 700) {
      this.searchNoFloat(anchoPantalla, 50);

    } else if (anchoPantalla >= 490) {
      this.sobreposicionaSearch("70%", anchoPantalla, 15)

    } else {
      this.sobreposicionaSearch("100%", anchoPantalla, 15)

    }
    // $(".searchMat").css("position", "absolute")
    // $(".searchMat").css("float", "center")
    // this.onOpen.emit();
    // this.closeActive.emit({ closeActive: true });
  }

  loginNoLogin() {
    if ($("#logoNoLogin").length) {
      return true
    } else if ($("logoNoLoginBamba")) {
      return false
    } else {
      return false
    }
  }

  searchNormal(anchoPantalla, porcentaje) {
    $(".mat-search_field .mat-form-field-infix").css("min-width", this.promedioPantalla(anchoPantalla, porcentaje));
  }
  searchNoFloat(anchoPantalla, porcentaje) {
    if (this.loginNoLogin()) {
      $(".tapaIMG").css("display", "block")
      $(".mat-search_field .mat-form-field-infix").css("min-width", this.promedioPantalla(anchoPantalla, porcentaje));
    } else {
      $(".tapaIMG2").css("display", "block")
      $(".mat-search_field .mat-form-field-infix").css("min-width", this.promedioPantalla(anchoPantalla, porcentaje));
    }
  }

  sobreposicionaSearch(left, anchoPantalla, porcentaje) {
    $(".logoDarwin").css("display", "none");
    $("#logoNoLogin").css("display", "none");

    $(".logoDarwin2").removeClass("d-block");
    $(".logoDarwin2").addClass("d-none");

    $(".iconoBambaNoLogin").removeClass("d-block");
    $(".iconoBambaNoLogin").addClass("d-none");


    $(".iconMenu").css("display", "none")
    $(".searchFloat").css("position", "absolute");
    $(".searchFloat").css("left", left);
    $(".searchFloat").css("z-index", "1");
    $(".mat-search_field .mat-form-field-infix").css("min-width", this.promedioPantalla(anchoPantalla, porcentaje));
  }

  promedioPantalla(anchoP, porcentaje) {
    let restante = 100 - porcentaje;
    let restanteFloat = restante / 100
    return (anchoP * restanteFloat)
  }

  onBlurring(searchValue: string) {
    this.searchVisible = false;
    this.onBlur.emit(searchValue);
    this.browseText = '';
    this.closeActive.emit({ closeActive: false });
  }

  onFocussing(searchValue: string) {
    this.onFocus.emit(searchValue);
  }


  /**
   * [searchText envia el el valor a buscar al api para que arroje los resulatados encontrados]
   * @return [description]
   */
  async searchText(query: string) {
    const res = await this.searchQuery(query).catch(e => {
      this.resultSearch = [];
      this.search = null;
    });
    if (res) {
      this.search = res;
      this.resultSearch = [];
      let tmp: ResultSearch = {
        letter: 'Regions',
        names: []
      };
      for (let i = 0; i < this.search.regions.length; i++) {
        tmp.names.push({
          name: this.search.regions[i].title,
          lemaxId: this.search.regions[i].lemaxId,
          typeId: 0,
          countrie: '',
          mainTheme: '',
          style: ''
        });
      }
      if (tmp.names.length > 0) {
        this.resultSearch.push(tmp);
      }
      tmp = {
        letter: 'Themes',
        names: []
      };
      for (let i = 0; i < this.search.themes.length; i++) {
        tmp.names.push({
          name: this.search.themes[i].name,
          lemaxId: this.search.themes[i].lemaxId,
          typeId: 0,
          countrie: '',
          mainTheme: '',
          style: ''
        });
      }
      if (tmp.names.length > 0) {
        this.resultSearch.push(tmp);
      }
      tmp = {
        letter: 'Countries',
        names: []
      };
      for (let i = 0; i < this.search.countries.length; i++) {
        tmp.names.push({
          name: this.search.countries[i].name,
          lemaxId: this.search.countries[i].lemaxId,
          typeId: 0,
          countrie: '',
          mainTheme: '',
          style: ''
        });
      }
      if (tmp.names.length > 0) {
        this.resultSearch.push(tmp);
      }
      // Trips es PRODUCTS y me tiene que llevar a product details no a shop
      let c = 0;
      tmp = {
        letter: 'Trips',
        names: []
      };
      for (let i = 0; i < this.search.products.length; i++) {
        ++c;
        if (c <= 5) {
          tmp.names.push({
            lemaxId: this.search.products[i].TourID,
            name: this.search.products[i].TourTitle,
            typeId: this.search.products[i].Type,
            countrie: (this.search.products[i].Countries.length > 0) ? this.search.products[i].Countries[0].name : '',
            style: (this.search.products[i].Style.length > 0) ? this.search.products[i].Style[0].Permalink : '',
            mainTheme: (this.search.products[i].MainTheme.length > 0) ? this.search.products[i].MainTheme[0].MainThemeName : ''
          });
        } else {
          tmp.names.push({
            lemaxId: -1,
            name: 'VIEW ALL',
            typeId: -1,
            countrie: '',
            style: '',
            mainTheme: ''
          });
          break;
        }
      }
      if (tmp.names.length > 0) {
        this.resultSearch.push(tmp);
      }
    }
  }

  setTextBrowse(value) {
    this.espejoText = value;
    this.browseText = value;
  }

  /**
   * [browse verificamos el tipo de elemento seleccionandode la respuesta del api
   * y lo mandamos a shop filtrado por country, region, theme y a product-detail si es un producto
   * solo muestra las primeras 5 coincidencias si hay mas de 5 y le dan clic en view all los lleva al
   * shop filtrado]
   * @param  d [opcion seleccionada]
   */
  browse(d: string = ''): void {
    const str = '';
    const url: string[] = [];
    this.browseText = this.searchControl.value;
    if (this.letterSelected !== 'Trips' || this.browseText.toLowerCase() === 'view all') {// shop
      url.push('shop');
      url.push('All');
      let txt = '0';
      let txt2 = '0';
      if (this.letterSelected.toUpperCase() == 'REGIONS' || this.letterSelected.toUpperCase() == 'COUNTRIES') {
        txt = this.letterSelected;
        txt2 = this.browseText;
      }
      url.push(txt);
      url.push(txt2);
      url.push('0');
      url.push('0');
      url.push('0');
      url.push('0');
      txt = '0';
      txt2 = '0';
      if (this.letterSelected.toUpperCase() == 'THEMES') {
        for (let i = 0; i < this.resultSearch.length; i++) {
          for (let j = 0; j < this.resultSearch[i].names.length; j++) {
            if (this.browseText.toUpperCase() == this.resultSearch[i].names[j].name.toUpperCase()) {
              txt = this.resultSearch[i].names[j].lemaxId + '';
              break;
            }
          }
        }
        localStorage.setItem('headerTxt', this.browseText);
      }
      url.push(txt);
      url.push('0');
      if (this.letterSelected == '') {
        url.push((this.browseText == '') ? '0' : this.browseText);
      } else {
        if (this.browseText.toLowerCase() == 'view all') {
          url.push(this.espejoText);
        } else {
          url.push('0');
        }
      }
      url.push('Relevance-High-to-Low');
    } else {
      // product detail
      // :countryStyleTheme/:tourTitle/:tourID/:type
      // url.push("product-detail");
      let b: boolean = false;
      for (let i = 0; i < this.resultSearch.length; i++) {
        if (this.resultSearch[i].letter == this.letterSelected) {
          for (let j = 0; j < this.resultSearch[i].names.length; j++) {
            if (this.resultSearch[i].names[j].name == this.browseText) {
              url.push('/adventure/' + this._quitSpacesService.quitSpaces(this.resultSearch[i].names[j].countrie) + '-' + this._quitSpacesService.quitSpaces(this.resultSearch[i].names[j].style) + '-' + this._quitSpacesService.quitSpaces(this.resultSearch[i].names[j].mainTheme));
              url.push(this._quitSpacesService.quitSpaces(this.resultSearch[i].names[j].name));
              url.push(this.resultSearch[i].names[j].lemaxId + '');
              url.push(this.resultSearch[i].names[j].typeId + '');
              b = true;
              break;
            }
          }
        }
        if (b) {
          break;
        }
      }
    }
    localStorage.setItem('header', 's');

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(url);
    });
    this.browseText = '';
    this.letterSelected = '';
    this.resultSearch = [];
    this.searchControl.setValue(null);
    this.onBlurring('');
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}

export interface SearchSelect {
  value: string;
  viewValue: string;
}

export class ResultSearch {
  letter: string;
  names: Data[];
}

export class Data {
  name: string;
  lemaxId: number;
  typeId: number;
  countrie: string;
  style: string;
  mainTheme: string;
}
