import {BrowserModule, DomSanitizer, HAMMER_GESTURE_CONFIG, HammerGestureConfig} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ResponsiveModule} from 'ngx-responsive';

import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {MetaModule} from '@ngx-meta/core';
// logearse con facebook y google
// rutas
import {APP_ROUTING} from './app.routers';
// components
import {AppComponent} from './app.component';
import {DropTargetOptions, MineTypeEnum, NgxUploadModule} from '@wkoza/ngx-upload';
import {ConfirmModule} from './components/confirm/confirm.module';
import {ToastrModule} from 'ngx-toastr';
import {JwtInterceptor} from './core/interceptors/http.interceptor';
import {HeaderModule} from './components/shared/header/header.module';
import {FooterModule} from './components/shared/footer/footer.module';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {Location} from '@angular/common';
import {NgxMaskModule} from 'ngx-mask';
import {Homev2Module} from './modules/homev2/homev2.module';
import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';
import {MatIconRegistry} from '@angular/material';

export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    'pan': {direction: 6},
    'swipe': {direction: 6},
    'pinch': {enable: false},
    'rotate': {enable: false}
  };
}


export const ngxDropTargetOptions: DropTargetOptions = {
  color: 'dropZoneColor',
  colorDrag: 'dropZoneColorDrag',
  colorDrop: 'dropZoneColorDrop',
  multiple: true,
  accept: [MineTypeEnum.Image, MineTypeEnum.Application_Pdf]
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    APP_ROUTING,
    BrowserAnimationsModule,
    HttpClientModule,
    MetaModule.forRoot(),
    NgxUploadModule.forRoot(ngxDropTargetOptions),
    ToastrModule.forRoot(),
    ConfirmModule,
    HeaderModule,
    FooterModule,
    Homev2Module,
    ResponsiveModule.forRoot(),
    NgxMaskModule.forRoot(),
    LazyLoadImageModule.forRoot({}),
    ScrollToModule.forRoot()
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    Location,
    MatIconRegistry
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'clock_60',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/clock_60.svg')
    );
  }
}
