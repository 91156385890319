<!-- <mat-form-field class="mat-search_field" [@slideInOut]="searchVisible">
  <input #input matInput type="text" placeholder="Where do you want to go?" [(ngModel)]="value" (ngModelChange)="updateChanges()" (blur)="onBlurring(input.value)" (keyup.enter)="onEnterring(input.value)" (focus)="onFocussing(input.value)" />
</mat-form-field> -->
<mat-form-field class="mat-search_field searchMat"
                id="search-field-nav"
                [@slideInOut]="searchVisible"
  [ngStyle]="{'opacity': searchVisible?'1':'0'}">
  <input #input matInput type="search" [formControl]="searchControl" placeholder="Where do you want to go55?"
    [matAutocomplete]="autoGroup">
  <mat-autocomplete #autoGroup="matAutocomplete" class="autocomplete">
    <mat-optgroup *ngFor="let r of resultSearch" (click)="close()" [label]="r.letter"
      (click)="letterSelected = r.letter;browse()">
      <mat-option *ngFor="let name of r.names" (click)="close()" [value]="name.name"
        [ngClass]="{'bold-option': name.name == 'VIEW ALL'}">
        {{ name.name }}
      </mat-option>
    </mat-optgroup>
  </mat-autocomplete>
</mat-form-field>
<span class="mat-search_icons" [class.mat-search_icons--active]="searchVisible">
  <mat-icon class="mat-search_icon-close" style="margin-right: 40px;" (click)="close()" matRipple>close</mat-icon>
  <mat-icon class="mat-search_icon-search" style="margin-right: 40px;" (click)="open()" matRipple>search</mat-icon>
</span>
